import { useState } from "react";
import PhotoAlbum from "react-photo-album";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
// import optional lightbox plugins
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import './App.css';

const photos = [
  { src: "/images/img20231221_10264676.jpg", width: 2032, height: 1456 },
  { src: "/images/img20231221_10282090.jpg", width: 1464, height: 2040 },
  { src: "/images/img20231221_10325377.jpg", width: 1752, height: 1312 },
  { src: "/images/img20231221_10362802.jpg", width: 2040, height: 1553 },
  { src: "/images/img20231221_10384085.jpg", width: 1474, height: 2039 },
  { src: "/images/img20231221_10391993.jpg", width: 2067, height: 1512 },
  { src: "/images/img20231221_10372232.jpg", width: 1503, height: 2077 },
  { src: "/images/img20231221_10401657.jpg", width: 1527, height: 2088 },
  { src: "/images/img20231221_10494093.jpg", width: 1509, height: 2115 },
  { src: "/images/img20231221_10522650.jpg", width: 1506, height: 2088 },
  { src: "/images/img20231221_10413785.jpg", width: 1529, height: 2113 },
  { src: "/images/img20231221_10425831.jpg", width: 1518, height: 2081 },
  { src: "/images/img20231221_10434252.jpg", width: 1516, height: 2104 },
  { src: "/images/img20231221_10441681.jpg", width: 1514, height: 2082 },
  { src: "/images/img20231221_10450009.jpg", width: 1526, height: 2076 },
  { src: "/images/img20231221_10453581.jpg", width: 2985, height: 2062 },
  { src: "/images/img20231221_10481514.jpg", width: 2107, height: 1514 },
  { src: "/images/img20231221_10490046.jpg", width: 2096, height: 1512 },
  { src: "/images/img20231221_10504462.jpg", width: 1514, height: 2076 },
  { src: "/images/img20231221_10514431.jpg", width: 1477, height: 2031 },
  { src: "/images/img20231221_10525764.jpg", width: 1510, height: 2116 },
  { src: "/images/img20231221_10533323.jpg", width: 1516, height: 2102 },
  { src: "/images/img20231221_10550251.jpg", width: 1471, height: 2027 },
  { src: "/images/img20231221_10553941.jpg", width: 2077, height: 1536 },
  { src: "/images/img20231221_10562612.jpg", width: 1515, height: 2081 },
  { src: "/images/img20231221_10573188.jpg", width: 2077, height: 1509 },
  { src: "/images/img20231221_10584483.jpg", width: 1790, height: 1205 },
  { src: "/images/img20231221_10591316.jpg", width: 2117, height: 1506 },
  { src: "/images/img20231221_10470325.jpg", width: 1528, height: 2092 },
  ];

export default function App() {
  const [index, setIndex] = useState(-1);

  return (
    <>
      <h1>A collection of your photos on cards</h1>
      <p>When I was in college, you began sending me these cards with your photographs. It was a joy to receive them in the mail, and I found it so thoughtful that you would take the time to create these pieces of art and send them to me. I of course saved them for almost 30 years. Devin and I scanned them in high resolution and made this gallery for you. You can really see the evolution of your style during the beginning of your time in California. There are also some of your Christmas-themed cards here as well. I hope you enjoy them. </p>
      <p>Thank you for being such a wonderful mother. I love you so much.</p>
      <p>with Love,
        PJ
      </p>
      <PhotoAlbum photos={photos} layout="rows" targetRowHeight={400} onClick={({ index }) => setIndex(index)} />

      <Lightbox
        slides={photos}
        open={index >= 0}
        index={index}
        close={() => setIndex(-1)}
        // enable optional lightbox plugins
        plugins={[Fullscreen, Slideshow, Thumbnails, Zoom]}
      />
      <p>&copy; Maria A. Urquilla. Website by PJ & Devin Urquilla</p>
    </>
  );
}